import Vue from "vue";
import CountryFlag from 'vue-country-flag';
import LangFlag from 'vue-lang-code-flags';
import CCrudFilters from '@/components/widgets/CCrudFilters';
import CLanguagesAutocomplete from '@/components/widgets/CLanguagesAutocomplete';
import CPropertiesAutocomplete from '@/components/widgets/CPropertiesAutocomplete';
import CCompaniesAutocomplete from '@/components/widgets/CCompaniesAutocomplete';
import CCountriesAutocomplete from '@/components/widgets/CCountriesAutocomplete';
import CCurrenciesAutocomplete from '@/components/widgets/CCurrenciesAutocomplete';
import CTimezonesAutocomplete from '@/components/widgets/CTimezonesAutocomplete';
import CUsersAutocomplete from '@/components/widgets/CUsersAutocomplete';
import CUnitsAutocomplete from '@/components/widgets/CUnitsAutocomplete';
import CReservationsSearch from '@/components/widgets/CReservationsSearch';
import CResourcesAutocomplete from '@/components/widgets/CResourcesAutocomplete';
import CRefundableMenu from '@/components/widgets/CRefundableMenu';
import CMealPlanMenu from '@/components/widgets/CMealPlanMenu';
import CReservationSources from '@/components/widgets/CReservationSources';
import VDate from '@/components/widgets/VDate';
import VClock from '@/components/widgets/VClock';
import VDateTime from '@/components/widgets/VDateTime';
import VBoolean from '@/components/widgets/VBoolean';
import VLocale from '@/components/widgets/VLocale';
import VConfirmation from '@/components/widgets/VConfirmation';
import VAddressAutocomplete from '@/components/widgets/VAddressAutocomplete';
import VListElements from '@/components/widgets/VListElements';
import FormHeader from '@/components/layouts/partials/FormHeader';

const ComponentsService = {
  /**
   *
   */
  init() {
    this.initFlags();
    this.initCrudFilters();
    this.initFormHeader();
  },

  /**
   *
   */
  initFlags() {
    Vue.component('country-flag', CountryFlag);
    Vue.component('lang-flag', LangFlag);
  },

  /**
   *
   */
  initCrudFilters() {
    Vue.component('c-crud-filters', CCrudFilters);
    Vue.component('c-languages-autocomplete', CLanguagesAutocomplete);
    Vue.component('c-properties-autocomplete', CPropertiesAutocomplete);
    Vue.component('c-companies-autocomplete', CCompaniesAutocomplete);
    Vue.component('c-countries-autocomplete', CCountriesAutocomplete);
    Vue.component('c-currencies-autocomplete', CCurrenciesAutocomplete);
    Vue.component('c-timezone-autocomplete', CTimezonesAutocomplete);
    Vue.component('c-users-autocomplete', CUsersAutocomplete);
    Vue.component('c-reservations-autocomplete', CReservationsSearch);
    Vue.component('c-resources-autocomplete', CResourcesAutocomplete);
    Vue.component('c-units-autocomplete', CUnitsAutocomplete);
    Vue.component('c-reservations-search', CReservationsSearch);
    Vue.component('c-refundable-menu', CRefundableMenu);
    Vue.component('c-meal-plan-menu', CMealPlanMenu);
    Vue.component('c-reservation-sources', CReservationSources);
    Vue.component('v-date', VDate);
    Vue.component('v-clock', VClock);
    Vue.component('v-date-time', VDateTime);
    Vue.component('v-boolean', VBoolean);
    Vue.component('v-locale', VLocale);
    Vue.component('v-confirmation', VConfirmation);
    Vue.component('v-address-autocomplete', VAddressAutocomplete);
    Vue.component('v-list-elements', VListElements);
  },

  /**
   *
   */
  initFormHeader() {
    Vue.component('form-header', FormHeader);
  }
};

export default ComponentsService;
