import Vue from "vue";
import VueDebounce from 'vue-debounce';
import VueLazyInput from 'vue-lazy-input';
import VueMoment from 'vue-moment';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import VueMobileDetection from "vue-mobile-detection";
import VueStripeWrapper from "@/libraries/vue-stripe-wrapper/src";


import stripeConfig from '@/config/stripe';

const LibrariesService = {
  /**
   *
   */
  init() {
    this.initVueMoment();
    this.initVuelidate();
    this.initVueDebounce();
    this.initVueLazyInput();
    this.initVueMobileDetection();
  },

  /**
   *
   */
  initVueLazyInput() {
    Vue.use(VueLazyInput);
  },

  /**
   *
   */
  initVueMoment() {
    Vue.use(VueMoment, {
      moment
    })
  },

  /**
   *
   */
  initVuelidate() {
    Vue.use(Vuelidate);
  },

  /**
   *
   */
  initVueDebounce() {
    Vue.use(VueDebounce);
  },

  /**
   *
   */
  initVueStripeWrapper() {
    Vue.use(VueStripeWrapper, {
      secret: stripeConfig.keys.public
    })
  },

  /**
   *
   */
  initVueMobileDetection() {
    Vue.use(VueMobileDetection);
  },
};

export default LibrariesService;
